export enum RouterName {
  Home = 'home',
  Explorer = 'explorer',
  TransactionsList = 'transactions-list',
  Transaction = 'transaction',
  BlocksList = 'blocks-list',
  Block = 'Block',
  Stats = 'stats',
  NotFound = 'not-found',
}
