import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { CardRow } from '@shared/ui/CardRow/CardRow';
import { useQuery } from '@tanstack/react-query';

export interface BlockInfoProps {
  id: number;
}
export const BlockInfo: FC<BlockInfoProps> = ({ id }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getBlockById({ id }),
    queryKey: [QueryKey.Block, id],
    select: (data) => data['block-data'],
  });

  return (
    <div>
      <CardRow name="ID" value={data?.id} wide loading={isLoading} />
      <CardRow
        name={t('labels.creationTime')}
        value={moment(data?.timestamp).format('DD MMM YYYY г., HH:mm:ss')}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.transactionsCount')}
        value={data?.['transaction-count']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.blockHeaderDigest')}
        value={data?.['block-header-digest']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.previousBlockDigest')}
        value={data?.['previous-block-digest']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.round')}
        value={data?.['round']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.merkleRoot')}
        value={data?.['merkle-root']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.numberOfSigners')}
        value={data?.['number-of-signers']}
        wide
        loading={isLoading}
      />
    </div>
  );
};
