import { FC, SVGProps } from 'react';

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = 32,
  width = 32,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 134 134"
    fill="none"
    {...rest}>
    <path
      d="M52.7587 128.885C51.6654 128.885 50.6214 128.457 49.8487 127.697C49.8487 127.697 28.7144 106.85 10.9997 89.4121C-3.67343 74.9724 -3.66521 51.4744 11.0162 37.0428L47.5059 1.18176C49.1089 -0.393921 51.7147 -0.393921 53.3177 1.18176C54.9206 2.75745 54.9206 5.31895 53.3177 6.89464L16.8279 42.7556C5.3442 54.044 5.3442 72.4109 16.8115 83.6992C30.5558 97.2259 46.3633 112.813 52.7587 119.124L102.064 70.6574C103.963 68.7908 103.963 65.7526 102.064 63.886L80.5103 42.691L59.8199 63.0214L61.0118 64.193C62.6148 65.7687 62.6148 68.3302 61.0118 69.9059C59.4089 71.4816 56.8031 71.4816 55.2001 69.9059L51.1064 65.8819C49.5035 64.3062 49.5035 61.7447 51.1064 60.169L77.6003 34.1257C79.2033 32.55 81.8091 32.55 83.4121 34.1257L107.884 58.1731C112.989 63.1911 112.989 71.3523 107.884 76.3703L55.6687 127.697C54.896 128.457 53.852 128.885 52.7587 128.885Z"
      fill="url(#paint0_linear_204_52828)"></path>
    <path
      d="M83.2396 130C82.1874 130 81.1352 129.604 80.3296 128.82C78.7267 127.245 78.7267 124.683 80.3296 123.107L116.811 87.2464C128.295 75.958 128.295 57.5911 116.828 46.3028C103.083 32.7761 87.2758 17.1889 80.8804 10.8781L31.5753 59.3446C29.6764 61.2112 29.6764 64.2494 31.5753 66.116L53.1699 87.3433L77.0662 64.3868C78.6938 62.8273 81.2914 62.8596 82.8779 64.4514C84.4644 66.0433 84.4316 68.6048 82.8122 70.1643L56.0141 95.9086C54.4029 97.452 51.83 97.4439 50.2353 95.8763L25.7635 71.8208C20.6587 66.8028 20.6587 58.6416 25.7635 53.6236L77.9786 2.29667C78.7514 1.53711 79.7953 1.11693 80.8886 1.11693C81.9819 1.11693 83.0259 1.54519 83.7986 2.30475C83.7986 2.30475 104.933 23.1523 122.648 40.5899C137.321 55.0296 137.313 78.5276 122.631 92.9593L86.1496 128.82C85.344 129.604 84.2918 130 83.2396 130Z"
      fill="url(#paint1_linear_204_52828)"></path>
    <defs>
      <linearGradient
        id="paint0_linear_204_52828"
        x1="55.854"
        y1="128.884"
        x2="55.854"
        y2="-0.00420225"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF2C00"></stop>
        <stop offset="0.648" stopColor="#FE8F00"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_204_52828"
        x1="77.7903"
        y1="130.002"
        x2="77.7903"
        y2="1.11289"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF2C00"></stop>
        <stop offset="0.648" stopColor="#FE8F00"></stop>
      </linearGradient>
    </defs>
  </svg>
);
