import { useMemo } from 'react';

import { api } from '@shared/libs/api';
import { QueryKey, SupportedAssetItem } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

export const useAssets = () => {
  const { data } = useQuery({
    queryFn: () => api.getBlockchainState(),
    queryKey: [QueryKey.BlockchainState],
    select: (data) => data?.['supported-assets'],
  });

  const assetsByCode = useMemo(() => {
    const assets: Record<string, SupportedAssetItem> = {};

    if (data) {
      for (const item of data) {
        assets[item.code] = item;
      }
    }

    return assets;
  }, [data]);

  const getNameByCode = (code: string) => {
    if (assetsByCode[code]) {
      return assetsByCode[code]?.name?.toUpperCase();
    }

    return code.toUpperCase();
  };

  return {
    assets: data || [],
    assetsByCode,
    getNameByCode,
  };
};
