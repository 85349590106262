import { FC, PropsWithChildren } from 'react';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface SettingsSectionProps {
  title: string;
}
export const SettingsSection: FC<PropsWithChildren<SettingsSectionProps>> = ({
  children,
  title,
}) => (
  <div className={styles.settingsSection}>
    <Typography
      className={styles.settingsSection__title}
      variant="s-medium"
      color="gray-400"
      uppercase>
      {title}
    </Typography>
    {children}
  </div>
);
