import { FC, PropsWithChildren } from 'react';

import { Base } from '../Base/Base';
import { Header, HeaderProps } from '../Header/Header';

interface BaseLayoutProps {
  header?: HeaderProps;
}
export const BaseLayout: FC<PropsWithChildren<BaseLayoutProps>> = ({
  children,
  header,
}) => <Base headerSlot={<Header {...header} />}>{children}</Base>;
