import React, { FC, PropsWithChildren, SelectHTMLAttributes } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

interface TableTd {
  name?: string;
  colspan?: number;
  fullWidth?: boolean;
}
export const TableTd: FC<
  PropsWithChildren<TableTd & SelectHTMLAttributes<HTMLTableCellElement>>
> = ({ children, className, colspan = 1, fullWidth, name, ...rest }) => (
  <td
    className={cx(styles.table__td, `table__td_${name}`, className)}
    colSpan={colspan}
    {...rest}>
    <Typography
      tag={'div'}
      variant={'s-regular'}
      className={cx(
        styles.table__cell,
        fullWidth && styles.table__cell_fullWidth,
      )}>
      {children}
    </Typography>
  </td>
);
