import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import { BlockNavigation } from '@features/Block';
import {
  BlockEscortSignatures,
  BlockInfo,
  BlockTransactions,
} from '@widgets/Blocks';

import styles from './styles.module.scss';

export interface BlockPageProps {
  params: {
    id: string;
  };
}
export const BlockPage: FC<BlockPageProps> = ({ params }) => {
  const id = +params.id;
  const { t } = useTranslation();

  return (
    <div className={styles.block}>
      <Card titlePy={2} titlePx={2} title={t('titles.blockInfo')}>
        <BlockInfo id={id} />
      </Card>
      <BlockNavigation currentBlock={id} />
      <Card titlePy={2} titlePx={2} title={t('titles.escortSignatures')}>
        <BlockEscortSignatures id={id} />
      </Card>
      <Card titlePy={2} titlePx={2} title={t('titles.transactions')}>
        <BlockTransactions id={id} />
      </Card>
    </div>
  );
};
