import { FC } from 'react';
import { ContextIcon, IconButton } from 'dekube-ui-kit';

import { useModal } from '@/shared';

export const HeaderSettings: FC = () => {
  const openSettingsModal = useModal('appSettings');

  return (
    <IconButton
      icon={ContextIcon}
      size="small"
      onClick={() => openSettingsModal()}
    />
  );
};
