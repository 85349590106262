import React, { FC, PropsWithChildren, SelectHTMLAttributes } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

interface TableTh {
  name?: string;
  width?: number | string;
}
export const TableTh: FC<
  PropsWithChildren<TableTh & SelectHTMLAttributes<HTMLTableCellElement>>
> = ({ children, className, name, width, ...reset }) => (
  <th
    className={cx(styles.table__th, name && styles[name], className)}
    style={{ width }}
    {...reset}>
    <Typography tag={'div'} variant={'s-medium'} className={styles.table__cell}>
      {children}
    </Typography>
  </th>
);
