import { FC } from 'react';
import { Typography } from 'dekube-ui-kit';

import { Card } from '@/shared';

import styles from './styles.module.scss';

export interface StatsInfoItemProps {
  label: string;
  value: string;
}
export const StatsInfoItem: FC<StatsInfoItemProps> = ({ label, value }) => (
  <Card py={1.5} px={2}>
    <Typography
      className={styles.statsInfoItem__label}
      variant="s-regular"
      color="gray-400"
      tag="h4">
      {label}
    </Typography>
    <Typography
      className={styles.statsInfoItem__value}
      variant="xl-medium"
      color="black">
      {value}
    </Typography>
  </Card>
);
