import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

interface SkeletonProps {
  inline?: boolean;
  className?: string;
  height?: number;
  fullWidth?: boolean;
}
export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  children,
  className,
  fullWidth,
  height = 24,
  inline,
}) => (
  <div
    className={cx(
      styles.skeleton,
      inline && styles.skeleton_inline,
      fullWidth && styles.skeleton_fullWidth,
      className,
    )}
    style={{ height }}>
    {children}
  </div>
);
