import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';
import { formatAmount, RightArrowIcon, Typography } from 'dekube-ui-kit';

import {
  CellLink,
  CopyHash,
  EasyTable,
  getAmountByAsset,
  getShortAddress,
  useAssets,
} from '@/shared';
import { RouterName } from '@app/router';
import { TransactionsFiltersState } from '@features/Transactions';
import { api } from '@shared/libs/api';
import {
  QueryKey,
  TransactionListItem,
  TransactionType,
} from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const pageSize = 50;

export interface TransactionsTableProps {
  filters: TransactionsFiltersState;
}
export const TransactionsTable: FC<TransactionsTableProps> = ({ filters }) => {
  const { navigate } = useNavigate();
  const { getNameByCode } = useAssets();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      api.getTransactionsList({
        page: 1,
        pageSize,
        ...filters,
      }),
    queryKey: [QueryKey.TransactionsList, filters],
    refetchInterval: 10_000,
  });
  const headers = [
    { title: '', value: 'link', width: 40 },
    { title: 'Block', value: 'block', width: 90 },
    { title: 'Type', value: 'type' },
    { title: 'Digest', value: 'digest' },
    { className: 'text-right-table', title: 'From', value: 'from' },
    { title: '', value: 'transfer', width: 48 },
    { title: 'To', value: 'to' },
    { title: 'Amount', value: 'amount' },
  ];

  const rowTemplate = (item: TransactionListItem<TransactionType>) => {
    const hasAmount =
      'asset' in item.description || 'amount' in item.description;
    const amountData = hasAmount
      ? // @ts-ignore
        getAmountByAsset(item.description?.amount || item.description?.asset)
      : null;

    return {
      amount:
        hasAmount && amountData
          ? `${formatAmount(amountData.amount)} ${amountData.asset || getNameByCode(amountData.code!)}`
          : '-',
      block: (
        <Typography
          className="text-hover-underline cursor-pointer"
          variant="s-regular"
          color="blue-400"
          onClick={() => navigate(RouterName.Transaction, { id: item['ID'] })}>
          {item['block-id']}
        </Typography>
      ),
      digest: <CopyHash hash={getShortAddress(item.digest, 6, 6)} />,
      from:
        'from' in item.description ? (
          <CopyHash hash={getShortAddress(item.description.from)} />
        ) : (
          '-'
        ),
      link: (
        <CellLink
          to={RouterName.Transaction}
          params={{ id: item.description['transaction-id'] }}
          disabled={!item.description['transaction-id']}
        />
      ),
      to:
        'from' in item.description ? (
          <CopyHash hash={getShortAddress(item.description.from)} />
        ) : (
          '-'
        ),
      transfer: (
        <RightArrowIcon
          width={20}
          height={20}
          color="var(--gray-600)"
          style={{ opacity: hasAmount ? 1 : 0 }}
        />
      ),
      type: item.description['transaction-type'],
      uuid: item.digest,
    };
  };

  const tableItems = useMemo(
    () => (data?.['transactions'] || []).map(rowTemplate),
    [data],
  );

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={pageSize}
    />
  );
};
