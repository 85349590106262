import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import {
  BlockByDayChart,
  MainStatsNumbers,
  RoundByDayChart,
} from '@widgets/Stats';

import styles from './styles.module.scss';

export const StatsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.stats}>
      <div className={styles.stats__numbersWrap}>
        <MainStatsNumbers />
      </div>
      <Card height={340} titlePx={2} titlePy={2} title={t('titles.blockByDay')}>
        <BlockByDayChart />
      </Card>
      <Card height={340} titlePx={2} titlePy={2} title={t('titles.roundByDay')}>
        <RoundByDayChart />
      </Card>
    </div>
  );
};
