import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Loader, SearchIcon, TextField } from 'dekube-ui-kit';

import { useExplorerSearch } from '@features/Explorer/libs/hooks';

import styles from './styles.module.scss';

export const ExplorerHeaderSearch: FC = () => {
  const { t } = useTranslation();
  const { error, isFocus, isLoading, onChange, setIsFocus, value } =
    useExplorerSearch({ external: true });

  return (
    <TextField
      className={cx(
        styles.explorerHeaderSearch,
        isFocus && styles.explorerHeaderSearch_focus,
        !!error && styles.explorerHeaderSearch_error,
      )}
      name="search"
      label={t('placeholders.findExplorerShort')}
      size="small"
      value={value}
      prependSlot={<SearchIcon />}
      appendSlot={isLoading ? <Loader size={24} width={2} /> : <></>}
      onChange={onChange}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />
  );
};
