import { FC } from 'react';
import { Link } from 'react-justanother-router';
import cx from 'classnames';

import { RouterName } from '@app/router';
import { LinkIcon } from '@shared/ui/icons';

import styles from './styles.module.scss';

export interface CellLinkProps {
  to: RouterName;
  params?: object;
  query?: object;
  disabled?: boolean;
}
export const CellLink: FC<CellLinkProps> = ({
  disabled,
  params,
  query,
  to,
}) => (
  <Link
    to={to}
    params={params}
    query={query}
    className={cx(styles.cellLink, disabled && styles.cellLink_disabled)}>
    <LinkIcon />
  </Link>
);
