import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import { TransactionsTable } from '@entities/Transactions';
import {
  TransactionsFilters,
  TransactionsFiltersState,
} from '@features/Transactions';
import { MainStatsNumbers } from '@widgets/Stats';

import styles from './styles.module.scss';

export const TransactionsPage: FC = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<TransactionsFiltersState>({});

  return (
    <div className={styles.transactions}>
      <MainStatsNumbers />
      <Card
        titlePy={1}
        titlePx={2}
        title={t('titles.transactions')}
        headRightSlot={
          <TransactionsFilters initValue={filters} onChange={setFilters} />
        }>
        <TransactionsTable filters={filters} />
      </Card>
    </div>
  );
};
