import { createRoot } from 'react-dom/client';

import { Root } from './app';

import 'dekube-ui-kit/dist/index.css';

const rootNode = document.querySelector('#root')!;
const root = createRoot(rootNode);

root.render(<Root />);
