import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WithCloseModal } from 'dekube-ui-kit';

import { DialogWrapper } from '@/shared';
import { SettingsSection } from '@entities/Settings';

import { LanguageSelector } from '../LanguageSelector/LanguageSelector';

declare global {
  interface ModalProps {
    appSettings: void;
  }
}
export const AppSettingsModal: FC<WithCloseModal> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <DialogWrapper title={t('titles.settings')} onClose={onClose}>
      <SettingsSection title={t('titles.lang')}>
        <LanguageSelector />
      </SettingsSection>
    </DialogWrapper>
  );
};
