import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Typography } from 'dekube-ui-kit';

import { Card } from '@/shared';
import { ExplorerPromoSearch } from '@features/Explorer';
import { NodePointMap } from '@widgets/Nodes';
import { MarketChart } from '@widgets/Stats';

import styles from './styles.module.scss';

export const HomePage: FC = () => (
  <div className={styles.home}>
    <Typography
      className={styles.home__title}
      variant="xxl-medium"
      color="black">
      <Trans
        i18nKey="titles.homePromo"
        components={{
          1: <Typography tag="span" variant="xxl-medium" color="blue-400" />,
        }}
      />
    </Typography>
    <div className={styles.home__search}>
      <ExplorerPromoSearch />
    </div>
    <div className={styles.home__content}>
      <div className={styles.home__col}>
        <Card className={styles.home__map}>
          <NodePointMap />
        </Card>
        <Card py={2} px={2}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique. enim
          fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique. enim
          fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
        </Card>
      </div>
      <div className={styles.home__col}>
        <Card py={2} px={2}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
        </Card>
        <Card py={2} px={2}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          enim fuga, incidunt magnam modi molestias porro quos rerum sequi ut?
          Ducimus ea fugit incidunt minus molestias nemo non qui similique.
        </Card>
      </div>
      <div className={styles.home__col}>
        <Card>
          <MarketChart />
        </Card>
      </div>
    </div>
  </div>
);
