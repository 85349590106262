import { FC } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export interface NavItemProps {
  label: string;
  to: RouterName;
  active?: boolean;
}
export const NavItem: FC<NavItemProps> = ({ active, label, to }) => (
  <Typography
    variant="s-medium"
    to={to}
    className={cx(styles.navItem, active && styles.navItem_active)}
    uppercase>
    {label}
  </Typography>
);
