import { FC, useEffect, useRef, useState } from 'react';
import {
  ColorType,
  createChart as createLightweightChart,
  IChartApi,
  UTCTimestamp,
} from 'lightweight-charts';

import { formatTickMarks, Skeleton } from '@/shared';

import { DatasetType } from '../types';

import styles from './styles.module.scss';

export interface ChartMiniProps {
  labels: number[];
  datasets: DatasetType[];
}
export const ChartMini: FC<ChartMiniProps> = ({ datasets, labels }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<IChartApi>();
  const [skeletonChart, setSkeletonChart] = useState<IChartApi>();
  const [newLabels, setNewLabels] = useState<number[]>();
  const createChart = () => {
    if (labels.length === 0 || datasets.length === 0) {
      return;
    }
    chart?.remove();
    skeletonChart?.remove();

    const lineColor = '#02977e';
    const lineSecondaryColor = '#02977e00';
    const opacityColor = '#02977e99';
    const lineRedColor = '#f74c4c';
    const lineSecondaryRedColor = 'rgba(227,91,91,0)';
    const opacityRedColor = 'rgba(247,76,76,.4)';

    const chartPointColor = '#666';

    const newChart = createLightweightChart(chartRef.current!, {
      crosshair: {
        horzLine: {
          labelVisible: false,
          visible: false,
        },
        vertLine: {
          labelVisible: false,
          visible: false,
        },
      },
      grid: {
        horzLines: {
          visible: false,
        },
        vertLines: {
          visible: false,
        },
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        vertTouchDrag: false,
      },
      height: chartRef.current!.clientHeight,
      layout: {
        background: {
          color: 'transparent',
          type: ColorType.Solid,
        },
        textColor: chartPointColor,
      },
      overlayPriceScales: {
        scaleMargins: {
          bottom: 0.05,
          top: 0.8,
        },
      },
      rightPriceScale: {
        borderVisible: false,
        scaleMargins: {
          bottom: 0.2,
          top: 0.19,
        },
        visible: false,
      },
      timeScale: {
        allowBoldLabels: false,
        borderVisible: false,
        fixLeftEdge: true,
        fixRightEdge: true,
        lockVisibleTimeRangeOnResize: true,
        minimumHeight: 38,
        rightBarStaysOnScroll: true,
        tickMarkFormatter: formatTickMarks,
        tickMarkMaxCharacterLength: 10,
        timeVisible: true,
        visible: false,
      },
    });

    setChart(newChart);

    const data = labels
      .map((label, index) => ({
        time: Math.floor(label / 1000) as UTCTimestamp,
        value: datasets[0].data[index],
      }))
      .sort((a, b) => a.time - b.time);

    setNewLabels(
      labels.map((label) => Math.floor(label / 1000)).sort((a, b) => a - b),
    );
    const firstPointValue = data[0]?.value || 0;
    const currentPointValue = data.at(-1)?.value || 0;

    const newAreaSeries = newChart.addAreaSeries({
      bottomColor:
        currentPointValue < firstPointValue
          ? lineSecondaryRedColor
          : lineSecondaryColor,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      lineColor: currentPointValue < firstPointValue ? lineRedColor : lineColor,
      lineType: 2,
      lineWidth: 2,
      priceLineVisible: false,
      priceScaleId: 'right',
      topColor:
        currentPointValue < firstPointValue ? opacityRedColor : opacityColor,
    });

    newAreaSeries.setData(data);

    newChart.timeScale().fitContent();
  };
  const createSkeletonChart = () => {
    chart?.remove();
    skeletonChart?.remove();

    const skeletonColor = '#00000019';

    const newChart = createLightweightChart(chartRef.current!, {
      crosshair: {
        horzLine: { visible: false },
        mode: 0,
        vertLine: { visible: false },
      },
      grid: {
        horzLines: { color: 'transparent', visible: false },
        vertLines: { color: 'transparent', visible: false },
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        vertTouchDrag: false,
      },
      height: chartRef.current!.clientHeight,
      layout: {
        background: { color: 'transparent', type: ColorType.Solid },
        textColor: skeletonColor,
      },
      rightPriceScale: {
        borderVisible: false,
        visible: false,
      },
      timeScale: {
        borderVisible: false,
        minimumHeight: 50,
        visible: false,
      },
    });

    setSkeletonChart(newChart);

    const newAreaSeries = newChart.addAreaSeries({
      bottomColor: 'transparent',
      lineColor: skeletonColor,
      lineType: 2,
      lineWidth: 2,
      topColor: skeletonColor,
    });

    const now = Date.now();
    const skeletonLabels = Array.from(
      { length: 25 },
      (_, i) => now - i * 60 * 1000,
    ).reverse();
    const skeletonDataset = Array.from(
      { length: 25 },
      () => Math.random() * 20 + 10,
    );

    const skeletonData = skeletonLabels.map((label, index) => ({
      time: Math.floor(label / 1000) as UTCTimestamp,
      value: skeletonDataset[index],
    }));

    newAreaSeries.setData(skeletonData);
    newChart.timeScale().fitContent();
  };

  useEffect(() => {
    if (skeletonChart) {
      createChart();
    }
  }, [datasets, skeletonChart]);

  useEffect(() => {
    createSkeletonChart();

    return () => {
      chart?.remove();
      skeletonChart?.remove();
    };
  }, []);

  return (
    <div ref={chartContainerRef} className={styles.chartMini}>
      <div ref={chartRef} className={styles.chartMini__chart}></div>
      <div className={styles.chartMini__labelsContainer}>
        {newLabels?.length ? (
          <div>{formatTickMarks(newLabels[0])}</div>
        ) : (
          <Skeleton>........</Skeleton>
        )}
        {newLabels && newLabels.length > 1 ? (
          <div>
            {formatTickMarks(newLabels[Math.floor((newLabels.length - 1) / 2)])}
          </div>
        ) : (
          <Skeleton>........</Skeleton>
        )}
        {newLabels && newLabels.length > 2 ? (
          <div>{formatTickMarks(newLabels.at(-1)!)}</div>
        ) : (
          <Skeleton>........</Skeleton>
        )}
      </div>
    </div>
  );
};
