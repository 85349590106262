import { useTranslation } from 'react-i18next';

import { LangListType, Language } from '@shared/types';

export interface UseLanguageResult {
  current: Language;
  items: LangListType;
  onChange(lang: Language): void;
}
export const useLanguage = (): UseLanguageResult => {
  const { i18n } = useTranslation();
  const langList = {
    [Language.English]: { label: 'English' },
    [Language.Russian]: { label: 'Русский' },
  };

  const changeTranslation = (lang: Language) => {
    i18n.changeLanguage(lang);
  };

  return {
    current: i18n.language as Language,
    items: langList,
    onChange: changeTranslation,
  };
};
