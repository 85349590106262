import { ITransactionAmount, ITransferAsset } from '@shared/libs/types';

export const getAmountByAsset = (
  amount: ITransferAsset | ITransferAsset[] | ITransactionAmount[],
): { amount: string; code?: string; asset?: string } => {
  if (Array.isArray(amount) && amount.length > 0) {
    return 'amount' in amount[0]
      ? { amount: amount[0].amount, code: amount[0].code }
      : { amount: amount[0].amout, asset: amount[0].asset };
  }

  return amount as ITransferAsset;
};
