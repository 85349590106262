import { FC, PropsWithChildren } from 'react';
import { CloseIcon, IconButton, Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface DialogWrapperProps {
  title: string;
  onClose(): void;
}
export const DialogWrapper: FC<PropsWithChildren<DialogWrapperProps>> = ({
  children,
  onClose,
  title,
}) => (
  <div className={styles.dialogWrapper}>
    <header className={styles.dialogWrapper__header}>
      <Typography variant="l-medium" uppercase>
        {title}
      </Typography>
      <IconButton icon={CloseIcon} onClick={onClose} />
    </header>
    <div className={styles.dialogWrapper__content}>{children}</div>
  </div>
);
