import { FC, SVGProps } from 'react';

export const FilterEditIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = 16,
  width = 16,
  ...rest
}) => (
  <svg
    viewBox="0 0 18 18"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      fillRule="evenodd"
      fill="currentColor"
      clipRule="evenodd"
      d="M12 4C12 5.10457 12.8954 6 14 6C15.1046 6 16 5.10457 16 4C16 2.89543 15.1046 2 14 2C12.8954 2 12 2.89543 12 4ZM14 0C11.7909 0 10 1.79086 10 4C10 6.20914 11.7909 8 14 8C16.2091 8 18 6.20914 18 4C18 1.79086 16.2091 0 14 0ZM1 3C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H7.5C8.05229 5 8.5 4.55228 8.5 4C8.5 3.44772 8.05229 3 7.5 3H1ZM10.5 13C9.94771 13 9.5 13.4477 9.5 14C9.5 14.5523 9.94771 15 10.5 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H10.5ZM4 16C2.89543 16 2 15.1046 2 14C2 12.8954 2.89543 12 4 12C5.10457 12 6 12.8954 6 14C6 15.1046 5.10457 16 4 16ZM0 14C0 11.7909 1.79086 10 4 10C6.20914 10 8 11.7909 8 14C8 16.2091 6.20914 18 4 18C1.79086 18 0 16.2091 0 14Z"></path>
  </svg>
);
