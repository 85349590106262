import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { BlockNavigateButton } from '@entities/Blocks';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { LastShortIcon, LeftShortIcon, RightShortIcon } from '@shared/ui/icons';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.scss';

export interface BlockNavigationProps {
  currentBlock: number;
}
export const BlockNavigation: FC<BlockNavigationProps> = ({ currentBlock }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { data: lastBlockId } = useQuery({
    queryFn: () => api.getLastBlock(),
    queryKey: [QueryKey.LastBlock],
    refetchInterval: 20_000,
    select: (data) => +data['current-block-id'],
  });
  const disabledNextAction = !lastBlockId || lastBlockId <= currentBlock;

  const navigateBlock = (block: number) =>
    navigate(RouterName.Block, { id: block });

  return (
    <div className={styles.blockNav}>
      <BlockNavigateButton
        prependIcon={LeftShortIcon}
        disabled={currentBlock <= 0}
        onClick={() => navigateBlock(currentBlock - 1)}>
        {t('buttons.prevBlock')}
      </BlockNavigateButton>
      <div className={styles.blockNav__right}>
        <BlockNavigateButton
          appendIcon={RightShortIcon}
          onClick={() => navigateBlock(currentBlock + 1)}
          disabled={disabledNextAction}>
          {t('buttons.nextBlock')}
        </BlockNavigateButton>
        <BlockNavigateButton
          appendIcon={LastShortIcon}
          onClick={() => lastBlockId && navigateBlock(lastBlockId)}
          disabled={disabledNextAction}>
          {t('buttons.latest')}
        </BlockNavigateButton>
      </div>
    </div>
  );
};
