import { initReactI18next } from 'react-i18next';
import i18n, { Resource } from 'i18next';

import { isDev, LANG_STORAGE_KEY, LANGUAGES } from '@/shared';
import translationEN from '@shared/locales/en.json';
import { Language } from '@shared/types';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources: Resource = {
  en: {
    translation: translationEN,
  },
};

i18n.on('languageChanged', (lng) => {
  localStorage.setItem(LANG_STORAGE_KEY, lng);
});

export const languageInstall = (): Language => {
  const storageLang = localStorage.getItem(LANG_STORAGE_KEY) as Language;

  if (storageLang && LANGUAGES.includes(storageLang)) {
    return storageLang;
  }

  return Language.English;
};

i18n.use(initReactI18next).init({
  debug: isDev,
  interpolation: {
    escapeValue: false,
  },
  lng: languageInstall(),
  resources,
  returnNull: false,
  saveMissing: true,
  saveMissingTo: 'all',
});

declare global {
  interface Window {
    i18n: typeof i18n;
  }
}
window.i18n = i18n;

export { default } from 'i18next';
