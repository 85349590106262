import { FC } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import { getRandomArbitrary, Skeleton } from '@/shared';

import styles from './styles.module.scss';

export interface CardRowProps {
  name: string;
  value?: string | number;
  wide?: boolean;
  loading?: boolean;
}
export const CardRow: FC<CardRowProps> = ({ loading, name, value, wide }) => (
  <div className={cx(styles.cardRow, wide && styles.cardRow_wide)}>
    <Typography
      className={styles.cardRow__name}
      variant="s-medium"
      color="black">
      {name}
    </Typography>
    {loading ? (
      <Skeleton height={18}>
        {Array.from({ length: getRandomArbitrary(10, 40) })
          .fill(1)
          .join('')}
      </Skeleton>
    ) : (
      <Typography
        className={styles.cardRow__value}
        variant="s-regular"
        color="black">
        {value}
      </Typography>
    )}
  </div>
);
