import { Router } from 'react-justanother-router';
import { ModalProvider } from 'dekube-ui-kit';

import { QueryClientProvider } from '@tanstack/react-query';

import { componentDictionary, queryClient } from './config';
import { history, renderer, router } from './router';

import './styles/index.scss';

export const Root = () => (
  <QueryClientProvider client={queryClient}>
    <ModalProvider components={componentDictionary}>
      <Router history={history} router={router} renderer={renderer} />
    </ModalProvider>
  </QueryClientProvider>
);
