import { FC } from 'react';
import cx from 'classnames';
import { CheckIcon, Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface SelectorBox {
  label: string;
  active?: boolean;
  onClick?(): void;
}
export const SelectorBox: FC<SelectorBox> = ({ active, label, onClick }) => (
  <div
    className={cx(styles.selectorBox, active && styles.selectorBox_active)}
    onClick={onClick}>
    {active && (
      <div className={styles.selectorBox__dot}>
        <CheckIcon width={16} height={16} />
      </div>
    )}
    <Typography className={styles.selectorBox__title} variant="m-regular">
      {label}
    </Typography>
  </div>
);
