import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';
import { Typography } from 'dekube-ui-kit';

import { CopyHash, EasyTable, getShortAddress } from '@/shared';
import { RouterName } from '@app/router';
import { api } from '@shared/libs/api';
import { QueryKey, TransactionType } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { title: 'ID', value: 'id' },
  { title: 'Type', value: 'type' },
  { title: 'Digest', value: 'digest' },
  { title: 'Signature', value: 'signature' },
];

export interface BlockTransactionsProps {
  id: number;
}
export const BlockTransactions: FC<BlockTransactionsProps> = ({ id }) => {
  const { navigate } = useNavigate();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getBlockById({ id }),
    queryKey: [QueryKey.Block, id],
    select: (data) => data['block-data'].transactions,
  });

  const rowTemplate = (item: TransactionType) => ({
    digest: <CopyHash hash={getShortAddress(item.digest, 6, 6)} />,
    id: +item['transaction-id'] ? (
      <Typography
        className="text-hover-underline cursor-pointer"
        variant="s-regular"
        color="blue-400"
        onClick={() =>
          navigate(RouterName.Block, { id: item['transaction-id'] })
        }>
        {item['transaction-id']}
      </Typography>
    ) : (
      '-'
    ),
    signature: <CopyHash hash={getShortAddress(item.signature, 12, 12)} />,
    type: item['transaction-type'],
    uuid: item.digest,
  });

  const tableItems = useMemo(
    () => (data || []).filter((el) => el.seed).map(rowTemplate),
    [data],
  );

  return (
    <EasyTable
      headers={headers}
      items={tableItems}
      loading={isLoading}
      skeletonCount={3}
    />
  );
};
