import { FC, SVGProps } from 'react';

export const LinkIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = 16,
  width = 16,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.3"
      d="M7.665 9.301c-.155-.067-.338-.206-.549-.417a2.6 2.6 0 0 1 0-3.677l1.768-1.768a2.6 2.6 0 0 1 3.677 3.677l-1.167 1.167m-3.06-1.584c.156.067.339.206.55.417a2.6 2.6 0 0 1 0 3.677l-1.768 1.768A2.6 2.6 0 1 1 3.44 8.884l1.167-1.167"></path>
  </svg>
);
