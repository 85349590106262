import { FC, PropsWithChildren } from 'react';

import { Container } from '@/shared';

import styles from './styles.module.scss';

export interface BaseProps {
  headerSlot?: JSX.Element;
}
export const Base: FC<PropsWithChildren<BaseProps>> = ({
  children,
  headerSlot,
}): JSX.Element => (
  <div className={styles.base}>
    {headerSlot}
    <Container className={styles.base__content}>{children}</Container>
  </div>
);
