import axios from 'axios';

import { API_ENDPOINT, pagination } from '@/shared';
import {
  GetBlockByDayStatisticResult,
  GetBlockByIdParams,
  GetBlockByIdResult,
  GetBlockchainInfoResult,
  GetBlockchainStateResult,
  GetHeaderBlockOnIdResult,
  GetHistoryTradingsParams,
  GetHistoryTradingsResult,
  GetLastBlockResult,
  GetNodesResult,
  GetRoundByDayStatisticResult,
  GetTransactionsOnIdParams,
  GetTransactionsResult,
  PaginationParams,
} from '@shared/libs/types';

class Api {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private request(method: string, params?: Record<string, any>) {
    return axios
      .post(this.baseUrl, {
        id: 3,
        jsonrpc: '2.0',
        method,
        params: params || [],
        version: '1.0',
      })
      .then(({ data }) => data?.result);
  }

  public async getBlockchainState(): Promise<GetBlockchainStateResult> {
    return this.request('get-blockchain-state');
  }

  public async getBlockchainInfo(): Promise<GetBlockchainInfoResult> {
    return this.request('get-blockchain-info');
  }

  public async getNodes(): Promise<GetNodesResult> {
    return this.request('get-nodes');
  }

  public async getBlocksList(
    params: PaginationParams,
  ): Promise<GetHeaderBlockOnIdResult> {
    const { limit, offset } = pagination(params);

    return this.request('get-header-blocks-on-id', {
      'add-nodes': 1,
      'count-block': limit,
      offset,
    });
  }

  public async getHistoryTrading(
    params: GetHistoryTradingsParams,
  ): Promise<GetHistoryTradingsResult> {
    return this.request('get-history-trading', params);
  }

  public async getBlockByDayStatistic(): Promise<GetBlockByDayStatisticResult> {
    return this.request('get-block-by-day-statistic');
  }

  public async getRoundByDayStatistic(): Promise<GetRoundByDayStatisticResult> {
    return this.request('get-round-by-day-statistic');
  }

  public async getLastBlock(): Promise<GetLastBlockResult> {
    return this.request('get-current-block-id');
  }

  public async getBlockById(
    params: GetBlockByIdParams,
  ): Promise<GetBlockByIdResult> {
    return this.request('get-block-by-id', params);
  }

  public async getTransactionsList(
    params: GetTransactionsOnIdParams & PaginationParams,
  ): Promise<GetTransactionsResult> {
    const { limit, offset } = pagination(params);

    return this.request('get-transactions-on-id', {
      'count-transaction': limit,
      offset,
      ...params,
    });
  }
}

export const api = new Api(API_ENDPOINT);
