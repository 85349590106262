import {
  createElement,
  FC,
  PropsWithChildren,
  SelectHTMLAttributes,
} from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface BlockNavigateButtonProps {
  appendIcon?: FC<SelectHTMLAttributes<SVGSVGElement>>;
  prependIcon?: FC<SelectHTMLAttributes<SVGSVGElement>>;
  disabled?: boolean;
  onClick(): void;
}
export const BlockNavigateButton: FC<
  PropsWithChildren<BlockNavigateButtonProps>
> = ({ appendIcon, children, disabled, onClick, prependIcon }) => (
  <Typography
    tag="button"
    variant="s-regular"
    className={cx(
      styles.blockNavBtn,
      prependIcon && styles.blockNavBtn_withPrepend,
      appendIcon && styles.blockNavBtn_withAppend,
      disabled && styles.blockNavBtn_disabled,
    )}
    onClick={onClick}>
    {prependIcon &&
      createElement(prependIcon, {
        className: styles.blockNavBtn__icon,
      })}
    {children}
    {appendIcon &&
      createElement(appendIcon, {
        className: styles.blockNavBtn__icon,
      })}
  </Typography>
);
