import { FC, PropsWithChildren, SelectHTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export const Container: FC<
  PropsWithChildren<SelectHTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...rest }) => (
  <div className={cx(styles.container, className)} {...rest}>
    {children}
  </div>
);
