import { FC, useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import { api } from '@shared/libs/api';
import { IBlockData, INodeItem, QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

import '@elfalem/leaflet-curve';
import './leaflet.config';

import config from './const';

import 'leaflet/dist/leaflet.css';
import styles from './styles.module.scss';

interface IMarker {
  id: number;
  name: string;
  icon: L.Icon;
  position: {
    lat: number;
    lng: number;
  };
}
export const NodePointMap: FC = () => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [lines, setLines] = useState<L.Curve[]>([]);
  const { data: nodesData } = useQuery({
    queryFn: () => api.getNodes(),
    queryKey: [QueryKey.Nodes],
    refetchInterval: 5000,
  });
  const { data: blocksData } = useQuery({
    queryFn: () =>
      api.getBlocksList({
        page: 1,
        pageSize: 5,
      }),
    queryKey: [QueryKey.BlocksList],
    refetchInterval: 5000,
  });

  const clearLines = () => {
    for (const value of lines) {
      map?.removeLayer(value);
    }
    setLines([]);
  };
  const updateNodePoints = (nodes: INodeItem[], blocks: IBlockData[]) => {
    const redIcon = new L.Icon(config.redIcon);
    const blueIcon = new L.Icon(config.blueIcon);
    const bounds: [number, number][] = [];
    const newMarkers: IMarker[] = [];
    const nodes_keys: Record<
      string,
      {
        ip: string;
        lat: number;
        lon: number;
      }
    > = {};

    clearLines();
    if (!blocks[0]) {
      return;
    }
    const escort_signatures = blocks[0]['escort-signatures'];

    if (escort_signatures) {
      const root_node = escort_signatures[0];

      for (const value of nodes) {
        nodes_keys[value['public-key']] = {
          ip: value.address,
          lat: +value.latitude,
          lon: +value.longitude,
        };
      }
      const rootPoint = nodes_keys[root_node.key];

      let index = 0;

      for (const node of escort_signatures.slice(1)) {
        index++;
        const ip = nodes_keys[node.key]?.ip;
        const latd = nodes_keys[node.key]?.lat;
        const lon = nodes_keys[node.key]?.lon;

        if (!ip || !latd || !lon) {
          continue;
        }

        newMarkers.push(
          {
            icon: blueIcon,
            id: index,
            name: ip,
            position: {
              lat: latd,
              lng: lon,
            },
          },
          {
            icon: redIcon,
            id: 0,
            name: rootPoint.ip,
            position: {
              lat: rootPoint.lat,
              lng: rootPoint.lon,
            },
          },
        );
        bounds.push([latd, lon]);
        const offsetX = rootPoint.lon - lon;
        const offsetY = rootPoint.lat - latd;

        const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
          theta = Math.atan2(offsetY, offsetX);
        const thetaOffset = 3.14 / 10;
        const r2 = r / 2 / Math.cos(thetaOffset),
          theta2 = theta + thetaOffset;
        const midpointX = r2 * Math.cos(theta2) + lon,
          midpointY = r2 * Math.sin(theta2) + latd;
        const midpointLatLng: [number, number] = [midpointY, midpointX];

        const curvedPath = L.curve(
          [
            'M',
            [latd, lon],
            'Q',
            midpointLatLng,
            [rootPoint.lat, rootPoint.lon],
          ],
          {
            color: 'red',
            weight: 2,
          },
        ).addTo(map!);

        setLines((state) => [...state, curvedPath]);
      }
      newMarkers.push({
        icon: redIcon,
        id: 0,
        name: rootPoint.ip,
        position: {
          lat: rootPoint.lat,
          lng: rootPoint.lon,
        },
      });
      bounds.push([rootPoint.lat, rootPoint.lon]);
    }

    setMarkers(newMarkers);

    map?.fitBounds(L.latLngBounds(bounds), { maxZoom: 13 });
    map?.scrollWheelZoom.disable();
  };

  useEffect(() => {
    if (nodesData && blocksData && map) {
      updateNodePoints(nodesData, blocksData['block-data']);
    }
  }, [nodesData, blocksData]);

  return (
    <MapContainer
      ref={setMap}
      className={styles.nodeMap}
      center={config.centerCoords}
      zoom={config.zoom}
      minZoom={config.minZoom}
      zoomControl={false}
      scrollWheelZoom={false}>
      <TileLayer url={config.tileUrl} />
      {markers.map((marker, idx) => (
        <Marker key={idx} position={marker.position} icon={marker.icon}>
          <Tooltip content={marker.name} permanent />
        </Marker>
      ))}
    </MapContainer>
  );
};
