import { FC } from 'react';
import { Link } from 'react-justanother-router';
import cx from 'classnames';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { ExplorerHeaderSearch } from '@features/Explorer';
import { LogoIcon } from '@shared/ui/icons';

import { HeaderSettings } from '../HeaderSettings/HeaderSettings';
import { Navigation } from '../Navigation/Navigation';

import styles from './styles.module.scss';

export interface HeaderProps {
  transparent?: boolean;
  static?: boolean;
  hideSearch?: boolean;
}
export const Header: FC<HeaderProps> = ({
  hideSearch,
  static: posStatic,
  transparent,
}) => (
  <div
    className={cx(
      styles.header,
      transparent && styles.header_transparent,
      posStatic && styles.header_static,
    )}>
    <Container className={styles.header__container}>
      <div className={styles.header__left}>
        <Link to={RouterName.Home} className={styles.header__logo}>
          <LogoIcon />
        </Link>
        <Navigation />
      </div>
      <div className={styles.header__right}>
        {!hideSearch && <ExplorerHeaderSearch />}
        <HeaderSettings />
      </div>
    </Container>
  </div>
);
