import { useNavigate, useRouter } from 'react-justanother-router';

export interface UseRouterPath {
  checkActivePath(name: string): boolean;
}
export const useRouterPath = (): UseRouterPath => {
  const { urlFor } = useNavigate();
  const { history } = useRouter();

  const isActive = (name: string): boolean =>
    String(history.location.pathname).startsWith(urlFor(name));

  return {
    checkActivePath: isActive,
  };
};
