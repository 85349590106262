import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { PropsWithNativeTypes } from 'dekube-ui-kit/dist/cjs/types/src/types';

import styles from './styles.module.scss';

export const TableRow: FC<
  PropsWithChildren<PropsWithNativeTypes<{}, HTMLTableRowElement>>
> = ({ children, className, ...rest }) => (
  <tr className={cx(styles.table__tr, className)} {...rest}>
    {children}
  </tr>
);
