import { FC } from 'react';

import { SelectorBox, SelectorBoxWrapper, useLanguage } from '@/shared';
import { Language } from '@shared/types';

export const LanguageSelector: FC = () => {
  const { current, items, onChange } = useLanguage();

  return (
    <SelectorBoxWrapper>
      {Object.entries(items).map(([key, value]) => (
        <SelectorBox
          key={key}
          label={value.label}
          active={key === current}
          onClick={() => onChange(key as Language)}
        />
      ))}
    </SelectorBoxWrapper>
  );
};
