import { ElementType, FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
  CopyIcon,
  copyTextToClipboard,
  Tooltip,
  Typography,
  TypographyColorType,
  TypographyVariantType,
} from 'dekube-ui-kit';

import { getShortAddress } from '@/shared';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';
import { PropsWithNativeTypes } from 'dekube-ui-kit/dist/cjs/types/src/types';

import styles from './styles.module.scss';

export interface CopyHashProps {
  hash: string;
  label?: string;
  shorted?: boolean;
  delay?: number;
  variant?: TypographyVariantType;
  defaultColor?: TypographyColorType;
  copiedColor?: TypographyColorType;
  onClickHash?: Maybe<() => void>;
  disableTooltip?: boolean;
}
export const CopyHash: FC<
  PropsWithNativeTypes<CopyHashProps, HTMLDivElement>
> = ({
  className,
  copiedColor,
  defaultColor,
  delay = 2000,
  disableTooltip,
  hash,
  label,
  onClickHash,
  shorted,
  variant = 's-regular',
  ...rest
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState<boolean>(false);
  const handleCopy = async () => {
    copyTextToClipboard(hash);

    setCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  const handleClickHash = (e: MouseEvent<ElementType, MouseEvent>) => {
    if (onClickHash) {
      e.stopPropagation();
      onClickHash();
    }
  };
  const defaultColorValue = (() => {
    if (defaultColor) {
      return defaultColor;
    }

    return onClickHash ? 'blue-main' : 'black';
  })();
  const copiedColorValue = (() => {
    if (copiedColor) {
      return copiedColor;
    }

    return 'gray-400';
  })();

  return (
    <div className={cx(styles['copy-hash__wrapper'], className)} {...rest}>
      <Typography
        className={cx(
          styles['copy-hash__hash'],
          !!onClickHash && styles['copy-hash__hash_clicked'],
        )}
        data-testid="target-hash"
        variant={variant}
        tag="span"
        color={copied ? copiedColorValue : defaultColorValue}
        onClick={handleClickHash}>
        {shorted ? getShortAddress(label || hash) : label || hash}
      </Typography>
      <Tooltip
        className={styles['copy-hash__tooltip']}
        show={copied}
        disabled={disableTooltip}
        label={t('tooltips.successCopied')}>
        <CopyIcon
          className={styles['copy-hash__icon']}
          data-testid="copy-hash-icon"
          onClick={() => {
            handleCopy();
          }}
        />
      </Tooltip>
    </div>
  );
};
