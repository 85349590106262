import { useContext } from 'react';
import { ModalContext, ModalContextType } from 'dekube-ui-kit';

export const useModal = <T extends keyof ModalProps>(modalType: T) => {
  const { showModal } = useContext<ModalContextType>(ModalContext);

  return (modalProps?: ModalProps[T]) => {
    showModal(modalType, modalProps);
  };
};
