import { createElement } from 'react';
import {
  RendererFunction,
  RouteMatch,
  RouterEngine,
  RouterEngineInterface,
} from 'react-justanother-router';

import { routes } from './routes';

interface RenderRouteProperties {
  layout?: string;
  auth?: boolean;
}
export const router: RouterEngineInterface = new RouterEngine(routes);
export const renderer: RendererFunction<RenderRouteProperties> = (
  match,
): JSX.Element | null => {
  const props: RouteMatch = {
    params: match.params,
    query: match.query,
    ...match.props,
  };

  const target = createElement(match.component, props);

  if (match.props?.layout) {
    return createElement(match.props?.layout, props, target);
  }

  return target;
};
