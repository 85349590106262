import { forwardRef } from 'react';
import cx from 'classnames';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface ChartTooltipItem {
  label: string;
  suffix: string;
  value: string;
}

export interface ChartTooltipProps {
  tooltipHeader: string;
  tooltipItems: ChartTooltipItem[];
  position: { x: number; y: number };
  isVisible: boolean;
  firstPoint?: number;
}

export const ChartTooltip = forwardRef<HTMLDivElement, ChartTooltipProps>(
  (
    { firstPoint = 0, isVisible, position, tooltipHeader, tooltipItems },
    ref,
  ) => (
    <div
      ref={ref}
      className={styles.tooltip}
      style={{
        display: isVisible ? 'block' : 'none',
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}>
      <Typography variant="s-regular" className={styles.chartTooltip__header}>
        {tooltipHeader}
      </Typography>
      <div className={styles.chartTooltip__body}>
        {tooltipItems.map((item, index) => (
          <div key={index}>
            {index === 0 ? (
              <div
                className={cx(
                  styles.chartTooltip__line,
                  styles.chartTooltip__lineIndex,
                  firstPoint < +item.value && styles.chartTooltip__lineUp,
                  firstPoint >= +item.value && styles.chartTooltip__lineDown,
                )}
              />
            ) : (
              <div className={styles.chartTooltip__arrow} />
            )}
            <Typography
              variant="s-medium"
              color="gray-400"
              className={styles.chartTooltip__label}>
              {item.label}:
            </Typography>
            <Typography
              variant="s-medium"
              className={styles.chartTooltip__value}>
              {item.value} {item.suffix}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  ),
);
