import { FC } from 'react';
import cx from 'classnames';
import { CheckIcon, Typography } from 'dekube-ui-kit';

import { Maybe } from 'dekube-ui-kit/dist/cjs/types/src/core/types/helpers';

import styles from './styles.module.scss';

export interface SelectMenuItem {
  value: string | number;
  title: string;
  subtitle?: string;
}
export interface SelectMenuProps {
  value?: string | number;
  items: SelectMenuItem[];
  onChange(value: Maybe<string | number>): void;
}
export const SelectMenu: FC<SelectMenuProps> = ({ items, onChange, value }) => (
  <div className={styles.selectMenu}>
    {items.map((el) => (
      <div
        key={el.value}
        className={cx(
          styles.selectMenu__item,
          value === el.value && styles.selectMenu__item_active,
        )}
        onClick={() => onChange(el.value === value ? null : el.value)}>
        <div className={styles.selectMenu__text}>
          <Typography variant="s-medium" color="black">
            {el.title}
          </Typography>
          {el.subtitle && (
            <Typography variant="s-regular" color="gray-400">
              {el.subtitle}
            </Typography>
          )}
        </div>
        {value === el.value && (
          <div className={styles.selectMenu__checkWrap}>
            <CheckIcon width={16} height={16} color="var(--white)" />
          </div>
        )}
      </div>
    ))}
  </div>
);
