import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ChartLine } from '@/shared';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

export const RoundByDayChart = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getRoundByDayStatistic(),
    queryKey: [QueryKey.Rounds],
  });

  const labels = useMemo(
    () =>
      (data?.['round-by-day'] || []).map(
        (el, idx) =>
          moment(el.date || new Date())
            .subtract(idx, 'days')
            .unix() * 1000,
      ),
    [data],
  );
  const datasets = useMemo(
    () =>
      isLoading
        ? []
        : [
            {
              data: (data?.['round-by-day'] || []).map((el) => +el.count),
              label: t('titles.blockByDay'),
            },
          ],
    [data, isLoading],
  );

  return <ChartLine datasets={datasets} labels={labels} hideLegend />;
};
