import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Typography } from 'dekube-ui-kit';

import { Card, SelectMenu, SPACING } from '@/shared';
import { api } from '@shared/libs/api';
import { GetTransactionsOnIdParams, QueryKey } from '@shared/libs/types';
import { FilterEditIcon } from '@shared/ui/icons';
import { useQuery } from '@tanstack/react-query';

export type TransactionsFiltersState = GetTransactionsOnIdParams;
export interface TransactionsFiltersProps {
  initValue?: TransactionsFiltersState;
  onChange(value: TransactionsFiltersState): void;
}
export const TransactionsFilters: FC<TransactionsFiltersProps> = ({
  initValue,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<TransactionsFiltersState>(
    initValue || {},
  );
  const { data: transactionTypes } = useQuery({
    queryFn: () => api.getBlockchainInfo(),
    queryKey: [QueryKey.BlockchainInfo],
    select: (data) => data['supported-transaction-types'],
  });

  const transactionTypesItems = useMemo(
    () =>
      Object.entries(transactionTypes || {}).map(([title, value]) => ({
        title,
        value: +value,
      })),
    [transactionTypes],
  );

  useEffect(() => {
    onChange(filter);
    setIsOpenFilter(false);
  }, [filter]);

  return (
    <Dropdown
      value={isOpenFilter}
      onChange={setIsOpenFilter}
      targetSlot={
        <Button
          size="small"
          icon={() => FilterEditIcon({ height: 18, width: 18 })}
          variant="outlined"
          onClick={() => setIsOpenFilter(true)}
          active={isOpenFilter}>
          Filters
        </Button>
      }>
      <Card px={2} py={2} width={700}>
        <Typography
          style={{ marginBottom: SPACING }}
          variant="s-medium"
          color="gray-400"
          uppercase>
          {t('labels.transactionType')}
        </Typography>
        <SelectMenu
          value={filter['code-transaction']!}
          items={transactionTypesItems}
          onChange={(val) =>
            setFilter((state) => ({
              ...state,
              ['code-transaction']: val ? +val : null,
            }))
          }
        />
      </Card>
    </Dropdown>
  );
};
