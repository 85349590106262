import { FC } from 'react';
import cx from 'classnames';

import { Skeleton } from '@/shared';
import { DatasetType } from '@shared/ui/Charts/types';

import styles from './styles.module.scss';

export interface ChartLegendProps {
  datasets: DatasetType[];
  colors: string[];
  visibleSeries: boolean[];
  onToggleChart?(idx: number): void;
}

export const ChartLegend: FC<ChartLegendProps> = ({
  colors,
  datasets,
  onToggleChart,
  visibleSeries,
}) => (
  <div className={styles.chartLegend}>
    {datasets.length > 0 ? (
      <>
        {datasets.map((dataset, idx) => (
          <div
            className={cx(
              styles.chartLegend__row,
              !visibleSeries[idx] && styles.chartLegend__row_disabled,
            )}
            key={idx}>
            <div
              className={styles.chartLegend__item}
              onClick={() => onToggleChart && onToggleChart(idx)}>
              <div
                className={styles.chartLegend__item_dot}
                style={{ backgroundColor: colors[idx] }}
              />
              {dataset.label}
            </div>
          </div>
        ))}
      </>
    ) : (
      <>
        {[0, 1].map((el) => (
          <div className={styles.chartLegend__row} key={el}>
            <div className={styles.chartLegend__item}>
              <Skeleton>metric name</Skeleton>
            </div>
          </div>
        ))}
      </>
    )}
  </div>
);
